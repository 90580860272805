import { useCallback, useEffect, useState } from 'react';
import { listClassroom } from '../../../services';
import { useUserId } from './useUserId';
export const useClassroom = (contextType) => {
    const { managerId, studentId, teacherId } = useUserId(contextType);
    const [classroom, setClassroom] = useState();
    const [classrooms, setClassrooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const changeClassroom = (classrrom) => {
        setClassroom(classrrom);
        sessionStorage.setItem('@campus:classroom', classrrom.id);
    };
    const getClassrooms = useCallback(async () => {
        setLoading(true);
        const result = await listClassroom({ teacherId, studentId, managerId });
        const classId = sessionStorage.getItem('@campus:classroom');
        const data = result.data;
        if (data) {
            setClassrooms(data.items);
            const oldClass = data.items.find((classroom) => classroom.id === classId);
            const classrrom = oldClass ?? data.items[0];
            changeClassroom(classrrom);
        }
        setLoading(false);
    }, [teacherId, studentId, managerId]);
    useEffect(() => {
        getClassrooms();
    }, [getClassrooms]);
    return {
        classroom,
        classrooms,
        loading,
        changeClassroom
    };
};
