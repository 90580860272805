import React from 'react';
import { Route } from 'react-router-dom';
import routes, { basePath } from './pages/routes';
import LiteraryWorks from './pages/LiteraryWorks';
import LiteraryWork from './pages/LiteraryWork';
export function createVestibularRoutes(options) {
    return (React.createElement(Route, { path: basePath },
        React.createElement(Route, { index: true, element: React.createElement(LiteraryWorks, { ...options.pages, ...options.pages.literaryWorks }) }),
        React.createElement(Route, { path: routes.literaryWork, element: React.createElement(LiteraryWork, { ...options.pages, ...options.pages.literaryWork }) })));
}
