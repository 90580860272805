import axios from 'axios';
import NetworkError from '../exceptions/NetworkError';
class Response {
    data;
    statusCode;
    error;
    static fromData(data, statusCode) {
        const response = new Response();
        response.data = data;
        response.statusCode = statusCode;
        return response;
    }
    static error(e) {
        console.error(e);
        if (axios.isAxiosError(e)) {
            return Response.fromAxiosError(e);
        }
        else if (Response.isError(e)) {
            return Response.fromError(e);
        }
    }
    static isError(e) {
        return e !== undefined;
    }
    static fromError(e) {
        const response = new Response();
        response.error = NetworkError.fromError(e);
        return response;
    }
    static fromAxiosError(error) {
        const response = new Response();
        response.error = NetworkError.fromAxiosError(error);
        if (error.response) {
            const statusCode = error.response.status;
            response.statusCode = statusCode;
        }
        else {
            response.statusCode = 500;
        }
        return response;
    }
}
export default Response;
