import { Api } from '@campus/auth';
import { Discipline, Response } from '../models';
export const listStudentDisciplines = async (studentId, classId, stageId) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/student/${studentId}/class/${classId}/discipline`, { params: { stageId } });
        return Response.fromData(response.data
            .map((item) => Discipline.fromJson(item))
            .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name)), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
export const getDisciplinePendingCount = async (studentId, disciplineId) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/student/${studentId}/discipline/${disciplineId}/pending`);
        return Response.fromData(response.data, response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
export const listTeacherDiscipline = async (teacherId, classId) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/teacher/${teacherId}/class/${classId}/discipline`);
        return Response.fromData(response.data
            .map((item) => Discipline.fromJson(item))
            .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name)), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
export const listTeacherClassDiscipline = async (classId, stageId) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/class/${classId}/disciplines`, { params: { stageId } });
        return Response.fromData(response.data
            .map((item) => Discipline.fromJson(item))
            .sort((itemA, itemB) => itemA.name.localeCompare(itemB.name)), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
