import React, { useContext, useState, useEffect, createContext, useCallback } from 'react';
import { useContextManager } from '../../ContextManagerProvider';
import useTeacherId from '../../../hooks/useTeacherId';
import { listTeacherDiscipline, listTeacherClassDiscipline } from '../../../services/disciplines';
export const TeacherClassDisciplinesContext = createContext(undefined);
export const TeacherClassDisciplinesProvider = ({ children }) => {
    const [teacherDisciplines, setTeacherDisciplines] = useState(null);
    const [loadingTeacherDisciplines, setLoadingTeacherDisciplines] = useState(false);
    const [classDisciplines, setClassDisciplines] = useState(null);
    const [loadingClassDisciplines, setLoadingClassDisciplines] = useState(false);
    const { classroom, stage } = useContextManager();
    const teacherId = useTeacherId();
    const getClassDisciplinesData = useCallback(async () => {
        setLoadingClassDisciplines(true);
        const result = await listTeacherClassDiscipline(classroom.id, stage.id);
        if (result.data) {
            setClassDisciplines(result.data);
        }
        else {
            setClassDisciplines([]);
        }
        setLoadingClassDisciplines(false);
    }, [classroom.id, stage.id]);
    useEffect(() => {
        const getTeacherDisciplinesData = async () => {
            setLoadingTeacherDisciplines(true);
            const result = await listTeacherDiscipline(teacherId, classroom.id);
            if (result.data) {
                setTeacherDisciplines(result.data);
            }
            else {
                setTeacherDisciplines([]);
            }
            setLoadingTeacherDisciplines(false);
        };
        if (teacherDisciplines === null && !loadingTeacherDisciplines) {
            getTeacherDisciplinesData();
        }
    }, [teacherDisciplines, loadingTeacherDisciplines, teacherId, classroom.id]);
    return (React.createElement(TeacherClassDisciplinesContext.Provider, { value: {
            classDisciplines,
            loadingClassDisciplines,
            teacherDisciplines,
            loadingTeacherDisciplines,
            getClassDisciplinesData
        } }, children));
};
export const useClassDisciplines = () => useContext(TeacherClassDisciplinesContext);
